import React, { useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Enumerable from 'linq';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBookOpen, FaEye, FaPlus,FaBars, FaEarlybirds, FaOpencart, FaRegWindowClose  } from 'react-icons/fa';
import {FiEdit} from 'react-icons/fi';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import TextField from '@mui/material/TextField';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import CustomReportMain from './cstmRptMain'
import { height } from '@mui/system';
//import {HiViewColumns} from 'react-icons/Hi';
import {AiFillEye, AiOutlineFileSearch, AiOutlineFolderOpen, AiOutlineFundView} from 'react-icons/ai';
import { MdPreview } from 'react-icons/md';
import { BsEyeFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectTheme } from '../features/theme/themeSlice';


const CustomDashboardGrd = ({acctId, tmplRecords, rptListQual,menuStateData }) => {

  const TmpltNmRegex = new RegExp(/[~`!#$%\^&*+=\-\[\]\\;,/{}|\\":<>\?]/g);
  const navigate = useNavigate();
  const themes=useSelector(selectTheme);
  const[templateNm,settemplateNm] = React.useState("");
  const[templateDesc,settemplateDesc ]= React.useState("");
  const[crtdUserNm,setcrtdUserNm] = React.useState("");
  const[crtDt,settcrtDt] = React.useState("");
  const[modUserNm,setmodUserNm] = React.useState("");
  const[modDt,setmodDt] = React.useState("");

  const [valdMsg, setvaldMsg] = React.useState("");
  const [valdMsg2, setvaldMsg2] = React.useState("");
  const [rptCountMsg, setrptCountMsg] = React.useState("");
  const [tmpltDetails, SetTmpltDetails] = React.useState(tmplRecords);
  const [show, setShow] = React.useState(false);
  const [rptIds, setRptIds] = React.useState([]);
  const [tnm, SetTemplateNm] = React.useState("");
  const [tdsc, SetTemplateDesc] = React.useState("");
  const [btnStatus, setBtnStatus] = React.useState(true);
  const [btnStatus2, setBtnStatus2] = React.useState(true);
  const [btnStatus3, setBtnStatus3] = React.useState(true);
  const [rptList, setRptList] = React.useState(rptListQual);
  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState({
    templateId: 0,
  });
  var tempToken = JSON.parse(localStorage.getItem('token'));
  var disbStatus=true;
  if(acctId>0)
    disbStatus=false;
  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(
    process(tmpltDetails, initialDataState)
  );
  const EditCommandCell = (props) => {
    return (
      <td>
        <button 
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" title='Edit'
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <FiEdit></FiEdit>
        </button>
      </td>
    );
  };

  const OpenCstmRptPage = (tid, tnm) => {
    localStorage.setItem("tmpltIdFrmDshBrd", JSON.stringify(tid));
    //console.log(tnm);
    localStorage.setItem("tmpltNm", tnm);
    menuStateData(true);
    setShow(true);
    localStorage.setItem("CustomOpen",true);
   
  }

  const onDataStateChangeCustom = React.useCallback((e) => {
    

    
    var templateNmInd,templateDescInd,crtdUserNmInd,crtDtInd,modUserNmInd,modDtInd;

    if(e.dataState.filter!==undefined){
      if(e.dataState.filter.filters.length>0){
        templateNmInd = 0;
        templateDescInd = 0;
        crtdUserNmInd = 0;
        crtDtInd = 0;
        modUserNmInd = 0;
        modDtInd = 0;
       // for (var i = 0; i < e.stateColumns.length; i++) {
          for (var i = 0; i < e.target.columns.length; i++) {
          
          for (var j = 0; j < e.dataState.filter.filters.length; j++) {
             // a= e.target.columns[i].field;
            if (e.target.columns[i].field == e.dataState.filter.filters[j].filters[0].field) {
             // a.headerClassName="active";
              //Grid.columns[i].headerClassName =="active";
              if (e.dataState.filter.filters[j].filters[0].field == "templateNm")
              {
                settemplateNm("active")
                templateNmInd = 1
              }
              if (e.dataState.filter.filters[j].filters[0].field == "templateDesc")
              {
                 settemplateDesc("active")
                 templateDescInd= 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "crtdUserNm")
              {
                setcrtdUserNm("active")
                crtdUserNmInd = 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "crtDt")
              {
                settcrtDt("active")
                crtDtInd = 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "modUserNm")
              {
                setmodUserNm("active")
                modUserNmInd = 1;
              }
              if (e.dataState.filter.filters[j].filters[0].field == "modDt")
              {
                setmodDt("active")
                modDtInd = 1;
              }
  

              //dataState.columns[i].headerClassName = "";
              break;
            }
            else{
              if (templateNmInd != 1)
              {
                settemplateNm("")
              }
              if (templateDescInd != 1)
              {
                settemplateDesc("")
              }
              if (crtdUserNmInd != 1)
              {
                setcrtdUserNm("")
              }
              if (crtDtInd != 1)
              {
                settcrtDt("")
              }
              if (modUserNmInd != 1)
              {
                setmodUserNm("")
              }
              if (modDtInd != 1)
              {
                setmodDt("")
              }
              //e.target.columns[i].headerClassName="";
              //Grid.columns ="active";
            }
          }
        }
      }
      else{
        for (var i = 0; i < e.target.columns.length; i++) {   
              // e.target.columns[i].headerClassName="";
              settemplateNm("")
              settemplateDesc("")
              setcrtdUserNm("")
              settcrtDt("")
              setmodUserNm("")
              setmodDt("")
            }
          }

          
    }

    if(e.dataState.filter ==undefined)
      {
        settemplateNm("")
        settemplateDesc("")
        setcrtdUserNm("")
        settcrtDt("")
        setmodUserNm("")
        setmodDt("")
       
      }



    setDataState(e.dataState);
    setResult(process(tmplRecords, e.dataState));
  }, []);
  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "") {
        let tID = cellProps.dataItem["templateId"];
        let tNm = cellProps.dataItem["templateNm"];
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            <button className="btn btn-sm btn-outline-secondary" disabled={disbStatus} title='View' onClick={() => OpenCstmRptPage(tID, tNm)}><AiOutlineFolderOpen size={20}/> </button>
          </td>
        );
      }
    }
    return tdElement;
  };
  const handleShow = () => setShow(true);
  const handleClose = () =>{
    localStorage.setItem("CustomOpen",false);
    menuStateData(false);
    setShow(false);
    
    
  } 
  const getRptIDs = (e) => {
    
    const { value, checked } = e.target;
    if (e.target.checked) {
      setRptIds([...rptIds, value])
    }
    else {
      setRptIds(rptIds.filter((e) => e !== value));
    }
    console.log(`${rptIds} is ${checked}`);

    const elements = document.getElementsByName('checkbox');
    let checkedCount = 0;
    elements.forEach((element) => {
      if (element.checked) {
        checkedCount++;
      }
    })
    if (checkedCount >= 1 && checkedCount<=5) {
      setrptCountMsg("");
      setBtnStatus(false)
    } else {
      setrptCountMsg(checkedCount<1?"Please select atleast one report.":"Please select maximum five reports at a time.")
      setBtnStatus(true)
    }

  }
  const handleChangeTnm = (e) => {
    
    SetTemplateNm(e.target.value);
    let isValid = !TmpltNmRegex.test(e.target.value);
    
    if (!isValid) {
      setBtnStatus2(true);
      setvaldMsg("Special characters are not Allowed.");
    }

    if (e.target.value === "") {
      setBtnStatus2(true);
      setvaldMsg("Please Enter Template Name.");
    }

    if (isValid && e.target.value !== "") {
      setBtnStatus2(false);
      setvaldMsg("");
    }
  }
  const handleChangeTdesc = (e) => {
    SetTemplateDesc(e.target.value);
    let isValid = !TmpltNmRegex.test(e.target.value);

    if (!isValid) {
      setBtnStatus3(true);
      setvaldMsg2("Special characters are not Allowed.");
    }

    if (e.target.value === "") {
      setBtnStatus3(true);
      setvaldMsg2("Please Enter Template Description.");
    }
    if (isValid && e.target.value !== "") {
      setBtnStatus3(false);
      setvaldMsg2("");
    }

  }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData
    )
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        saveNewTemplate();

      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

  }
  const saveNewTemplate = async () => {
    
    let idXml = '<Root>';
    idXml = idXml + rptIds.map(function (val) {
      return val !== ',' ? '<Records><ReportId>' + val + '</ReportId></Records>' : '';
    })
    idXml = idXml + '</Root>'
    let RptIds = idXml;


    let TemplateNm = tnm;
    let TemplateDesc = tdsc;
    localStorage.setItem("tmpltNm", tnm);
    let UserId = JSON.parse(localStorage.getItem('userId'));
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    const data = { UserId, TemplateNm, TemplateDesc, RptIds };

    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };

    await axios.post('TemplateProfile/TemplateProfile', data, config)
      .then((response) => {

        //console.log(response);

        if (response.statusText === 'OK' || response.statusText === '') {
          localStorage.setItem("tmpltNm", tnm);
          setRptIds([]);
          SetTmpltDetails(response.data.ocTemplateProfile);
          
          if (response.data.saveTemplateId !== 0) {
            localStorage.setItem("tmpltIdFrmDshBrd", JSON.stringify(response.data.saveTemplateId));
            navigate("/cstmRptMain");
          }
        }

      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshToken();


        }
        console.log("my error is " + error);
      })

    handleClose();
  }
  const UpdateTmplt = async () => {
    
    let token = tempToken;
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    let TemplateId = localStorage.getItem('tIdForUpdt');
    let TemplateNm = localStorage.getItem('tNmForUpdt');
    let TemplateDesc = localStorage.getItem('tDescForUpdt');
    let InsrtUpdtInd = 1;
    let UserId = JSON.parse(localStorage.getItem('userId'));
    const postData = { TemplateId, TemplateNm, TemplateDesc, UserId, InsrtUpdtInd };

    await axios.post('TemplateProfile/UpdateTemplate', postData, config)
      .then(response => {
        
        const rowData = response.data;
        const templateData = rowData.ocTmpltDetailsT;
        const lstRpts = rowData.ocQualRptLstT;
        SetTmpltDetails(rowData.ocTmpltDetailsT);
        setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        setResult(rowData.ocTmpltDetailsT, initialDataState);
        // setRptList(rowData.oCQualRptLstT);                
      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshTokenForUpdt();


        }
        console.log("my error is " + error);
      })
  }
  const refreshTokenForUpdt = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData
    )
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        UpdateTmplt();

      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

  }
  const DateCell = (props) => {

    if (props.field === "crtDt") {
      let cdt = new Date(props.dataItem["crtDt"]);
      return (
        <td style={{ textAlign: 'left' }}>
          {(cdt.getMonth() + 1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
        </td>
      )
    }
    if (props.field === "modDt") {
      let cdt = new Date(props.dataItem["modDt"]);
      return (
        <td style={{ textAlign: 'left' }}>
          {(cdt.getMonth() + 1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
        </td>
      )
    }
  }
  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const enterEdit = (item) => {
    
    setOpenForm(true);
    setEditItem(item);
  };
  const handleSubmit = (event) => {
    
    localStorage.setItem('tIdForUpdt', event.templateId);
    localStorage.setItem('tNmForUpdt', event.templateNm);
    localStorage.setItem('tDescForUpdt', event.templateDesc);
    UpdateTmplt();
    // window.location.reload(false)
   // console.log("template updated");
    setOpenForm(false);
  };
  const handleCancelEdit = () => {
    console.log("template update cancelled");
    setOpenForm(false);
  };
  return (

    <div>
      <Modal style={{backgroundColor:themes==="dark"?"#292929":"inherit"}} fullscreen={true} show={show} onHide={handleClose}>
                 
                 <Modal.Body style={{backgroundColor:themes==="dark"?"#292929":"inherit"}}>

               {/* <div className='text-end justify-content-end d-flex sticky-top bg-light border-bottom border-1'>
                 <button className="btn btn-danger mt-5  btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Close" onClick={handleClose}>
                     <FaRegWindowClose></FaRegWindowClose>
                   </button>
                   </div>      */}
                 
                   <CustomReportMain customclose={setShow} menustate={menuStateData}></CustomReportMain>

                 </Modal.Body>
                 

               </Modal>
      
      <div className='row my-2'>
        <div className="col col-md-12 col-sm-12 py-2">
          {/* <div className="card-header tableheader py-1 mb-2">Custom Report</div> */}
          <div className='mx-1 px-1'>
            <Grid style={{ height: "70vh" }}

              //data={resultState.slice(page.skip, page.skip + page.take)}
              // groupable={{
              //   footer: "visible",
              // }}
              data={result}
              resizable={true}
              reorderable={true}
              sortable={true}
              onDataStateChange={onDataStateChangeCustom}
              {...dataState}
              cellRender={cellRender}
            >
              <Column field='templateNm' menu={true} title="Template Name" headerClassName={templateNm} width="auto" columnMenu={ColumnMenu} />
              <Column field='templateDesc' menu={true} title="Description" width="auto" headerClassName={templateDesc} columnMenu={ColumnMenu} />
              <Column field='crtdUserNm' menu={true} title="Created By" width="auto" headerClassName={crtdUserNm} columnMenu={ColumnMenu} />
              <Column field='crtDt' menu={true} title="Created Date" cell={DateCell} headerClassName={crtDt} width="auto" columnMenu={ColumnMenu} />
              <Column field='modUserNm' menu={true} title="Modified By" width="auto" headerClassName={modUserNm} columnMenu={ColumnMenu} />
              <Column field='modDt' menu={true} title="Modified Date" cell={DateCell} headerClassName={modDt} width="auto" columnMenu={ColumnMenu} />
              <Column field='' title='View' menu={true}  width="85px" />
              
            </Grid>
            
          </div>
          
          <div className="row mx-1 my-2">
            <div className="form">
              <div className="input-container">
                {/* <Modal fullscreen={true} show={show} onHide={handleClose}>
                 
                  <Modal.Body>

                {/* <div className='text-end justify-content-end d-flex sticky-top bg-light border-bottom border-1'>
                  <button className="btn btn-danger mt-5  btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Close" onClick={handleClose}>
                      <FaRegWindowClose></FaRegWindowClose>
                    </button>
                    </div>      
                  
                    <CustomReportMain customclose={setShow}></CustomReportMain>

                  </Modal.Body>
                  

                </Modal> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {themes==='dark'?
    <style>
      {`
      .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}

.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
    </style>:<></>}
    </div>
  )


}
export default CustomDashboardGrd